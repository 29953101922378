const formError = () => {
  const handle = (err, fields) => {
    const data = err.response.data.errors
    const formErrorFields = fields

    Object.keys(data).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const errorMessage = `${key}ErrorMessage`
        formErrorFields.value[errorMessage] = data[key]
      }
    })
  }

  const remove = (element, fields) => {
    const formErrorFields = fields
    const errorMessage = `${element}ErrorMessage`
    formErrorFields[errorMessage] = ''
  }

  return {
    handle,
    remove,
  }
}

export default formError
